import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/DefaultPageLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Exploring how to utilize LLMs to speedup development`}</h1>
    {
      /* cut */
    }
    <p>{`Recently, I got tasked with integrating another internal API into an LLM-based agent that I was working on. The application already had several APIs connected, but this new addition was crucial, and I was looking for a way to automate the integration process. The challenge wasn’t just about hooking up the API—it was about transforming the complex internal structures into something more digestible for the LLM. Basically, the API needed a makeover: stripping out internal entities, identifiers, and making the data coherent and contextually relevant for the AI.`}</p>
    <p>{`Now, generally speaking, adding a new API is a pretty clear-cut process—parse the endpoints, connect the dots, and fit it into the application. But it's also a time-consuming routine. It’s not just about writing the code but ensuring everything aligns perfectly, which eats up a lot of time, especially when scaling this up to integrate more APIs quickly. We wanted to simplify this routine, making it more efficient and repeatable.`}</p>
    <p>{`I’ve been using GitHub Copilot and ChatGPT extensively in my coding routine, and they’ve been great. But for this task, I wanted to see if there was a better, more streamlined way. That’s when I decided to experiment with Cursor Editor and a custom script approach, hoping they could give me that extra edge.`}</p>
    {
      /* cut */
    }
    <p>{`Integrating an API into a project typically involves four key components:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`API Client`}</strong>{`: Allows code to make requests to the API and understand its contracts.`}</li>
      <li parentName="ol"><strong parentName="li">{`Function Definition`}</strong>{`: A JSON schema of input parameters needed for OpenAI tools integration, as detailed in the `}<a parentName="li" {...{
          "href": "https://platform.openai.com/docs/guides/function-calling/parallel-function-calling"
        }}>{`OpenAI function calling guide`}</a>{`.`}</li>
      <li parentName="ol"><strong parentName="li">{`Function Implementation`}</strong>{`: The actual code that performs the required operations.`}</li>
      <li parentName="ol"><strong parentName="li">{`Tests`}</strong>{`: Ensures the function works as intended and supports future updates.`}</li>
    </ol>
    <h2>{`Tool #1: Cursor Editor`}</h2>
    <h3>{`Getting Started`}</h3>
    <p>{`I kicked things off with `}<a parentName="p" {...{
        "href": "https://www.trycursor.com"
      }}>{`Cursor Editor`}</a>{`, which, if you haven’t heard of it, is like a supercharged version of an IDE with AI baked in. It’s got this cool feature where it can read the codebase and provide insights into what’s going on. It’s kinda like having a buddy who knows just enough to help but still needs some guidance. So, I enabled code embeddings in Cursor, which gave me a broad picture of the project but no clear path on what I actually needed to do.`}</p>
    <p>{`When it came to generating code, I used `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/openapi-typescript-codegen"
      }}>{`openapi-typescript-codegen`}</a>{` for the basic models and let Cursor handle the heavy lifting of generating the API client. It was surprisingly good at piecing together most of the code, even catching some missing API requests, which was nice. But it wasn’t all smooth sailing—some requests still had to be adjusted manually.`}</p>
    <h3>{`Trying to Get Things Done`}</h3>
    <p>{`My first approach was simple: I just asked it to generate a function that could update or set some records, and… well, it didn’t exactly get the job done. The code it spit out was generic and often missed the context, which felt like talking to someone who heard the words but didn’t quite get the meaning.`}</p>
    <p>{`But after tweaking my prompt and adding more detail, I got something that almost worked. For example, I told it to create, update, or delete records while being careful with destructive operations (basically, keeping the old state so I could roll back if needed). The results were better, but still, there were parts where the AI kinda stumbled and needed some hand-holding to make the logic work correctly.`}</p>
    <p>{`One cool feature I found was the inline adjustments you could make using CTRL+K on selected code. It’s like having your very own pair programming assistant who doesn’t mind being bossed around—perfect for making step-by-step improvements. But honestly, even though it sped up the process a lot compared to doing everything manually, it wasn’t fully predictable. Some days, you get a lot of the code right on the first go; other days, you’re buried in hidden bugs that can drain your time.`}</p>
    <h3>{`The Cursor Editor Experience`}</h3>
    <p>{`Initially, Cursor Editor creates a noticeable "Wow" effect. However, as you work with it more, the excitement fades, and it starts to feel like a Copilot-powered IDE.`}</p>
    <p>{`But there are some standout features that make it worth using:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Predictive Suggestions`}</strong>{`: Cursor does this cool thing where it guesses what else you might need to change when you tweak one part of your code. Sometimes, it’s spot on and feels like magic. Other times, not so much, but it’s still a nice touch.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Inline Prompts and Adjustments (CTRL + K)`}</strong>{`: This was the real star for me. You highlight a bit of code, tell Cursor what you want, and it does it. It’s like having a super chill coworker who never gets tired of your constant “Hey, can you fix this?” requests.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Model Switching and Using Claude`}</strong>{`: The fact that you can swap models like changing channels is awesome. Claude’s great with larger contexts and code generation, and it’s right there without the hassle of setting up your own account.`}</p>
      </li>
    </ol>
    <h2>{`Tool #2: OpenAI-Based Script`}</h2>
    <p>{`The second tool in my bag of tricks was this script I whipped up with OpenAI’s help. The idea was for it to generate the function implementations directly—basically automating the boring bits. I brainstormed with ChatGPT, and it threw an estimate of 27 days to get the whole script right. That’s when I almost gave up, because the biggest challenge wasn’t writing the code itself but feeding the AI enough context so it wouldn’t mess up.
Instead of getting bogged down with parsing the whole OpenAPI spec, I decided to take a different approach. I generated TypeScript models directly from the OpenAPI specification and then wrote a TypeScript class—an API client—using those models. But instead of defining every possible endpoint, I focused on including only the methods I actually needed. This way, I kept things lean and focused, which made it easier for the AI to handle.`}</p>
    <p>{`The next part of the plan was to leverage this TypeScript API client as the entry point. I used the TypeScript compiler API to gather all the dependencies and relevant source code from the models used within the client. Once I had that neatly packaged, I fed it into the OpenAI ChatGPT-4 model as context. By providing this precise input, I was able to guide the AI more effectively and generate relevant code snippets without drowning in irrelevant details.`}</p>
    <p>{`ChatGPT was quick to generate the code, but debugging and refining took a couple of hours. Still, this approach made it way easier to control what the AI was working with, and once I got through the initial setup, writing prompts to get the rest of the code became pretty straightforward.`}</p>
    <p>{`Still, the quality of the API docs and the interfaces mattered a lot. Some parts of the API were confusing, even for the AI—like when it didn’t understand that a random string was just a synthetic ID. Sometimes, you have to spell things out to the AI, which can be a little annoying but still way faster than doing everything from scratch.`}</p>
    <p>{`Below is a simplified version of the script I used to generate the function definition, implementation, and unit tests for the records management function. I omitted many details, `}<em parentName="p">{`very`}</em>{` simplified prompts, and it is generalized to give you an idea of the process:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` OpenAI `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'openai'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span>{` fs `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'fs'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` path `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'path'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`collectTsCodeFromDependencies`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`filePath`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Logic to read and parse TypeScript files from dependencies would go here`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`// Mock content collected from `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`filePath`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Pretty familiar code for those who works with OpenAI API`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`async`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`runOpenAIGeneration`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`prompt`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` openAiClient`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` OpenAI`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` response `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`await`}</span>{` openAiClient`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`chat`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`completions`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`create`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        model`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'gpt-4o'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        messages`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` role`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'user'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` content`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` prompt `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`choices`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`m`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` m`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`message`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?.`}</span>{`content `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`||`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`join`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'\\n'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` openAiClient `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`OpenAI`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` apiKey`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'your-api-key'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` apiClientPath `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'./path/to/yourApiClient.ts'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` functionDefinitionExamplePath `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'./path/to/exampleFunctionDefinition.ts'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Collecting TypeScript context from dependencies`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` apiClientContent `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`collectTsCodeFromDependencies`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`apiClientPath`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` exampleFunctionDefinitionContent `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`collectTsCodeFromDependencies`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`functionDefinitionExamplePath`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` functionName `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'manageRecords'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` functionLogic `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'The function should manage records, allowing for operations such as creating, updating, and deleting records.'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` basicPrompt `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`You are generating TypeScript code for a records management application.
The function logic is as follows:
`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`functionLogic`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`

API Client Code:
`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`apiClientContent`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` functionDefinitionPrompt `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`
`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`basicPrompt`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`

Function Definition Example:
`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`exampleFunctionDefinitionContent`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`

====
Generate a function definition using the above API client code and function definition example.`}</span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` functionDefinition `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`await`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`runOpenAIGeneration`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`functionDefinitionPrompt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` openAiClient`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` functionDefinitionPath `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` path`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`resolve`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`path`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`join`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'./path/to/save'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`functionName`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`.ts`}</span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
fs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`writeFileSync`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`functionDefinitionPath`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` functionDefinition`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` flag`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'w'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` exampleFunctionImplementationPath `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'./path/to/exampleFunctionImplementation.ts'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` exampleFunctionImplementationContent `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`collectTsCodeFromDependencies`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`exampleFunctionImplementationPath`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` functionImplementationPrompt `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`
`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`basicPrompt`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`

Function Definition:
`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`functionDefinition`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`

Example Function Implementation:
`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`exampleFunctionImplementationContent`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`

====
Generate a function implementation based on the function definition.`}</span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` functionImplementation `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`await`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`runOpenAIGeneration`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`functionImplementationPrompt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` openAiClient`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` functionImplementationPath `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` path`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`resolve`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`path`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`join`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'./path/to/save'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`functionName`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`.ts`}</span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
fs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`writeFileSync`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`functionImplementationPath`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` functionImplementation`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` flag`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'w'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` functionTestsPrompt `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`
`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`basicPrompt`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`

Function Implementation:
`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`functionImplementation`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`

====
Generate unit tests for the function implementation using Jest.`}</span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` functionTests `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`await`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`runOpenAIGeneration`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`functionTestsPrompt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` openAiClient`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` functionTestsPath `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` path`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`resolve`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`path`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`join`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'./path/to/tests'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`functionName`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`.test.ts`}</span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
fs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`writeFileSync`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`functionTestsPath`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` functionTests`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` flag`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'w'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h3>{`Important Note on Prompts`}</h3>
    <p>{`The prompts used in the script above are quite simplified. In reality, it took about an hour to fine-tune them to generate code that was both modifiable and clear. Here's what I needed to clarify and specify:`}</p>
    <ul>
      <li parentName="ul">{`Detailed explanations of the API's functionality, especially where it isn't obvious from names or contracts.`}</li>
      <li parentName="ul">{`Definitions of terms unique to the domain that might not be familiar to the LLM.`}</li>
      <li parentName="ul">{`Instructions on libraries and non-standard practices used in the project.`}</li>
      <li parentName="ul">{`Guidelines on how to write tests that are easy to maintain and update.`}</li>
    </ul>
    <p>{`As you might imagine those are time-consuming, but at least partially reusable for the next tasks.`}</p>
    <h3>{`Results of the script`}</h3>
    <p>{`Well, the script worked. OpenAI was able to generate the function definition, implementation, and even the unit tests. But it wasn’t perfect.`}</p>
    <p>{`First of all TypesScript code was not correct everywhere. I had to fix manually compilation, sometimes adding missing details and make code compliant with the project lint rules.`}</p>
    <p>{`Next the tests required to tune a lot to make them runnable. Tests itself were missed the cases I needed, but when initially generated bunch was fixed, but old buddy Copilot was able to help to add more cases.`}</p>
    <p>{`Then I finally was able to fix logic, and make it work as expected.
I had to spend a couple of hours debugging and fixing the generated code, especially around the API client.
The API itself was a bit of a mess, which didn’t help.`}</p>
    <h2>{`Final Thoughts`}</h2>
    <p>{`So, what would I recommend?`}</p>
    <p>{`The ability to generate code with LLMs is impressive, but it still has limitations and can be confusing in larger contexts. However, it's far from useless. Scripts like the one created here are valuable for repetitive tasks where traditional code generation methods fall short.`}</p>
    <p>{`In my view, you need a substantial number of similar tasks to justify the time spent creating such scripts. While LLMs can fill a niche in automating logic code generation, expect to spend time fixing issues in the generated code. Simpler logic generally results in fewer mistakes, but more complex logic will require more effort to correct.`}</p>
    <p>{`For most cases, using LLMs through the Copilot-like extensions to generate smaller code snippets and then gradually integrating and refining them is a more reliable and scalable approach. This method suits the current state of LLMs better. As LLMs improve in reasoning and handling larger contexts, their ability to generate comprehensive code in a single step will also become more dependable. We'll have to see how this evolves in the future.`}</p>
    <p>{`What about Cursor? If you’re a developer using VS Code or any AI-assisted tool like Copilot, Cursor Editor is definitely worth a shot. It has some unique features that can save you a bunch of time, especially for making quick adjustments and working with larger codebases. It’s not perfect, but it’s a nice extra tool to have on your belt. But I bet that Copilot will catch up with some of the features soon. It looks like a race of features right now, and it is up to you to decide which tool gives more value in the current moment.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      